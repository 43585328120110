@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');
:root {
  --scroll: 0;
  --bg-position-x: 50%;
}

html, body { 
  background-color: #000;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.background-container {
  background-image: url('./assets/main-background.jpg');
  background-size: cover;
  background-position: var(--bg-position-x) center;
  width: 100%;
  height: 100vh;
  min-height: 100%;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient__text2 {
  background: var(--gradient-text2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

  .gradient__text3 {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-left: 5.9rem;

  }

  .App {
    background-color: #000000ef;
    background-size: cover; 
    background-position: center; 
    width: 100%;
  }

  
  
  .section__padding {
    padding: 4rem 6rem;
  }
  
  .section__margin {
    margin: 4rem 6rem;
  }
  
  .scale-up-center {
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
  }

@media screen and (max-width: 1400px) {
  :root {
    --bg-position-x: calc(50% + (1400px - 100vw) * 0.2);
  }
}