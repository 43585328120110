/* Footer Text */
.footer-text {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.footer-text p {
    letter-spacing: 3px;
    font-size: 18px;
    margin-top: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
}
  
  .footer-text p:first-child {
    color: #E5B761;
    margin-bottom: 10px;
  }
  
  .footer-text p:last-child {
    color: white;
    margin-top: 2px;
    margin-bottom: 100px;
  }

.footer-social {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
}

.footer-social a {
  color: #fff;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #E5B761;
}

@media screen and (max-width: 1200px) {
  .footer-text p {
    font-size: 17px;
  }
}

@media screen and (max-width: 992px) {
  .footer-text p {
    font-size: 16px;
    letter-spacing: 2.5px;
  }
  
  .footer-social {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .footer-text p {
    font-size: 15px;
    letter-spacing: 2px;
  }
  
  .footer-text p:last-child {
    margin-bottom: 80px;
  }
  
  .footer-social {
    gap: 1.2rem;
  }
}

@media screen and (max-width: 734px) {
    .footer-text p {
        font-size: 14px;
        letter-spacing: 1.5px;
        margin-top: 20px;
    }

    .footer-text p:last-child {
        padding: 0 50px;
        margin-bottom: 60px;
    }
    
    .footer-social {
      gap: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .footer-text p {
        font-size: 12px;
        letter-spacing: 1px;
        margin-top: 15px;
    }

    .footer-text p:last-child {
        padding: 0 20px;
        margin-bottom: 40px;
    }
    
    .footer-social {
      gap: 0.8rem;
    }
    
    .footer-social a svg {
      width: 28px;
      height: 28px;
    }
}