.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}


.game {
    margin-top: 50px;
    text-align: center;
    color: white;
    transform: translateY(-20px);
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .first {
    color: #E5B761;
  }
  
  .off-commission {
    color: #E5B761;
  }

  .description {
    color: #E5B761;
    text-align: center;
    font-size: 25px;
    width: 100%;
  }
  
  .info-with-image {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  
  .info-image {
    margin-top: 50px;
    margin-left: 80px; /* Adjust the margin as needed */
    width: 575px; /* Increased by 15% from 425px */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; 
  }
  
  .additional-info {
    margin-top: 50px;
    color: #fff;
    text-align: center;
    font-size: 30px;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.4;
  }

  .additional-info1 {
    margin-top: 50px;
    color: #fff;
    text-align: center;
    font-size: 30px;
    height: 100%;
    margin-bottom: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.4;
  }

  .additional-info1 p {
    margin-bottom: 15px;
  }

  .game h3 {
    color: #E5B761;
    font-weight: 700;
    margin-top: 80px;
    text-align: center;
    font-size: 40px;
  }


  .game h1 {
    color: #fff;
    font-weight: 700;
    margin-top: 80px;
    text-align: center;
    font-size: 48px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 734px) {
    .header {
      font-size: 3.5rem;
      padding: 40px 10px 20px;
    }
    
    .header h2 {
      font-size: 20px;
      margin-top: 25px;
      margin-bottom: 30px;
    }

  }

  @media screen and (max-width: 733px) {
    .game {
      text-align: left;
    }
    .header {
      font-size: 4.5rem;
      padding: 30px 1px 15px;
    }
    
    .header h2 {
      font-size: 30px;
      margin-top: -10px;
      margin-bottom: 10px;
      letter-spacing: 3px;
    }

  }

  @media screen and (max-width: 1200px) {
    .game h1 {
      font-size: 42px;
    }
    
    .additional-info, .additional-info1 {
      font-size: 26px;
    }
  }

  @media screen and (max-width: 992px) {
    .game h1 {
      font-size: 36px;
      margin-top: 60px;
    }
    
    .description {
      font-size: 22px;
    }
    
    .additional-info, .additional-info1 {
      font-size: 22px;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 768px) {
    .game {
      padding: 20px;
      transform: translateY(0);
      margin-bottom: 0;
    }

    .game h2 {
      font-size: 28px;
      letter-spacing: 2px;
      text-align: center;
      line-height: 1.3;
    }

    .game h1 {
      font-size: 30px;
      margin-top: 40px;
    }

    .game h3 {
      font-size: 28px;
      margin-top: 40px;
      text-align: center;
    }

    .description {
      font-size: 18px;
      padding-left: 0;
    }

    .info-with-image {
      flex-direction: column;
      align-items: center;
    }

    .info-image {
      width: 90%;
      max-width: 402px;
      height: auto;
      margin: 20px auto 0;
      order: 2;
    }

    .additional-info, .additional-info1 {
      font-size: 18px;
      margin-top: 20px;
      order: 1;
      padding: 0;
      width: 100%;
      text-align: center;
    }

    .additional-info p, .additional-info1 p {
      margin-bottom: 20px;
      line-height: 1.5;
    }

    .additional-info1 {
      text-align: center;
      margin-bottom: 10px;
    }

    /* Switch visibility for desktop/mobile versions */
    .desktop-only {
      display: none !important;
    }

    .mobile-only {
      display: block !important;
    }
  }

  @media screen and (max-width: 480px) {
    .game h2 {
      font-size: 22px;
    }

    .game h1 {
      font-size: 24px;
    }

    .game h3 {
      font-size: 22px;
    }

    .description {
      font-size: 16px;
    }

    .additional-info, .additional-info1 {
      font-size: 16px;
      line-height: 1.4;
    }

    .additional-info p, .additional-info1 p {
      margin-bottom: 15px;
      font-size: 16px;
    }
  }

/* Mobile paragraphs styling */
.additional-info.mobile-only p, 
.additional-info1.mobile-only p {
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 18px;
  color: #fff;
  text-align: left;
}

/* Medium screen sizes (769px - 1200px) */
@media screen and (min-width: 769px) and (max-width: 1335px) {
  .game {
    padding: 0 20px;
  }

  .info-with-image {
    flex-direction: column;
    align-items: flex-start;
  }

  .info-image {
    width: 90%;
    max-width: 495px; /* Increased by 15% from 425px */
    height: auto;
    margin: 30px auto 0;
    order: 2;
  }

  .additional-info.desktop-only {
    width: 100%;
    max-width: 100%;
    order: 1;
    padding-right: 20px;
    padding-left: 0;
  }

  .additional-info1.desktop-only {
    width: 100%;
    max-width: 100%;
    text-align: left;
    padding: 0;
  }

  .description {
    padding-left: 0;
    width: 100%;
  }

  .game h1 {
    text-align: left;
    padding-left: 0;
  }

  .game h3 {
    text-align: left;
    padding-left: 0;
  }
}