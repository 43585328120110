.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 60px 10px 30px;
    letter-spacing: 10px;
    font-size: 5.5rem;
    position: relative;
    margin-top: -20px;
    font-weight: 700;
    height: 90vh;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  .header h1{
    margin-top: -200px;
    width: 100%;
  }
  
  .header h2 {
    font-size: 40px;
    font-weight: normal;
    letter-spacing: 5px;
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
    .header {
      font-size: 5rem;
      padding: 50px 10px 25px;
    }
    
    .header h2 {
      font-size: 36px;
    }
  }

  @media screen and (max-width: 992px) {
    .header {
      font-size: 4.5rem;
      padding: 45px 10px 22px;
    }
    
    .header h2 {
      font-size: 32px;
      letter-spacing: 4px;
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      font-size: 4rem;
      padding: 40px 10px 20px;
      height: 80vh;
    }
    
    .header h2 {
      font-size: 28px;
      margin-top: 20px;
      letter-spacing: 3px;
    }
  }

  @media screen and (max-width: 734px) {
    .header {
      font-size: 3.5rem;
      padding: 40px 10px 20px;
    }
    
    .header h2 {
      font-size: 20px;
      margin-top: 25px;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 733px) {
    .header {
      font-size: 3rem;
      padding: 30px 10px 15px;
      height: 70vh;
    }
    
    .header h2 {
      font-size: 18px;
      margin-top: 15px;
      margin-bottom: 10px;
      letter-spacing: 2px;
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      font-size: 2.5rem;
      padding: 20px 10px 10px;
      letter-spacing: 5px;
      height: 60vh;
    }
    
    .header h2 {
      font-size: 16px;
      margin-top: 10px;
      letter-spacing: 1px;
    }
    
    .header h1 {
      margin-top: 30px;
    }
  }