/* Existing styles */

.waitlist-container {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; 
  text-align: center;
  background-color: #000; 
  padding: 20px;
  position: relative; 
  margin-bottom: 20px; /* Added margin to create space between container and footer */
}

.waitlist-container h1 {
  margin-top: 90px;
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: 3px;
  color: #ffffff;
}

.strike-logo {
  position: absolute;
  top: 35px;
  width: 75px; 
  height: auto; /* Maintain aspect ratio */
  cursor: pointer; /* Make the logo look clickable */
}

.inline-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; 
}

.inline-text .text1 {
  font-size: 1.2em;
  color: #fff;
  margin: 0; 
  font-weight: 700;
}

.inline-text .text2 {
  font-size: 1.2em;
  color: #E5B761;
  margin: 0; 
}

.waitlist-container p {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #E5B761;
}

#mc_embed_signup {
  width: 100%;
  max-width: 400px; 
}

#mc_embed_signup h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #E5B761;
}

.indicates-required {
  font-size: 0.9em;
  color: #E5B761;
  margin-bottom: 10px;
}

.mc-field-group {
  margin-bottom: 15px;
  width: 100%; 
}

.mc-field-group label {
  display: block;
  font-size: 1em;
  margin-bottom: 5px;
  color: #E5B761;
}

.waitlist-input {
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #E5B761; 
  border-radius: 5px;
  width: 100%; 
  font-size: 16px;
  box-sizing: border-box;
}

.waitlist-input:focus {
  border-color: #007bff;
  outline: none;
}

.asterisk {
  color: red;
}

.waitlist-button {
  text-transform: uppercase;
  padding: 15px 30px; 
  background-color: #E5B761; 
  color: black; 
  font-weight: 800;
  border: none;
  border-radius: 5px;
  font-size: 18px; 
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 350px; 
  box-sizing: border-box;
}

.waitlist-button:hover {
  background-color: #fff;
  transform: scale(1.05);
}

/* Responsive styles */
@media screen and (max-width: 600px) {
  .waitlist-container {
    padding: 10px;
    padding-top: 180px; /* Added padding to move content below the logo */
    /* Removed height: auto; as height is no longer set globally */
    justify-content: flex-start; /* Align items to the top */
    margin-bottom: 10px; /* Reduce bottom margin on small screens */
  }

  .waitlist-container h1 {
    font-size: 1.5em;
  }

  .waitlist-container p {
    font-size: 1em;
  }

  #mc_embed_signup h2 {
    font-size: 1.2em;
  }

  /* Hide the "Gain access..." text on very small screens */
  .inline-text .text1 {
    display: none;
  }

  /* Optional: Adjust the logo position if needed */
  .strike-logo {
    top: 20px; /* Adjust this value as necessary */
  }
}