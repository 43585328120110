/* Scroll Container */
.scroll-container {
    height: 350vh; /* Increased from 300vh to 350vh to provide more scrolling space */
    position: relative;
  }
  
  /* Dynamic Scroll */
  .dynamic-scroll {
    position: sticky;
    top: 10px;
    text-align: center;
    padding: 150px 0 200px 0; /* Increased bottom padding from 0 to 200px */
    font-family: 'Montserrat', sans-serif;
    height: 0vh; /* Holds during the "locked" phase */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .dynamic-scroll h2 {
    padding-top: 150px;
    font-size: 4rem;
    color: #E5B761;
    font-weight: normal;
    letter-spacing: 9px;
    font-family: 'Montserrat', sans-serif;
  }
  
  
  .scrolling-word-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  /* Scrolling Word Animation */
  .scrolling-word {
    position: absolute;
    top: 10;
    left: 0;
    width: 100%;
    opacity: 0; /* Start with 0 opacity except for the first word */
    text-align: center;
    font-size: 7rem;
    color: #E5B761;
    font-weight: bolder;
    margin-top: -20px;
    margin-bottom: 150px; /* Increased from 100px to 150px */
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    z-index: 1;
    letter-spacing: 8px;
  }
  
  /* Make the first word visible by default */
  
  .scrolling-word.active {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* AOS Animations */
  [data-aos="fade-in-up"] {
    opacity: 0;
    transform: translateY(30px);
  }
  
  [data-aos="fade-in-up"].aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Triggers */
  .triggers {
    position: absolute;
  }
  
  .trigger {
    height: 15vh; /* Adjust to control scroll distance between triggers */
  }
  
  .App {
    background-color: #000000ef;
    background-size: cover; 
    background-position: center; 
    width: 100%;
  }

  @media screen and (max-width: 734px) {
    .dynamic-scroll h2 {
      font-size: 2rem;
      letter-spacing: 6px;
    }

    .scrolling-word {
      font-size: 4rem;
      letter-spacing: 4px;
      margin-top: -10px;
    }

    .scroll-container {
      height: 325vh; /* Increased from 275vh to 325vh */
    }
  }

  @media screen and (max-width: 733px) {
    .dynamic-scroll h2 {
      font-size: 2.5rem;
      letter-spacing: 4px;
    }

    .scrolling-word {
      font-size: 3.5rem;
      letter-spacing: 3px;
      margin-top: -5px;
    }

    .scroll-container {
      height: 325vh; /* Increased from 275vh to 325vh */
    }
  }
