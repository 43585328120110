.gallery-wrap {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 2.5% auto;
  margin-top: 400px;
  box-sizing: border-box;
}

.gallery-wrap h2 {
  padding: 40px;
  font-size: 50px;
  text-align: center;
  color: #000;
  letter-spacing: 5px;
  background-color: white;
  width: 100%;  
}

.gallery {
  display: flex;
  animation: slideRight 40s linear infinite;
  width: fit-content;
}

.gallery-group {
  display: flex;
  gap: 40px;
  padding: 0 20px;
  padding-bottom: 50px;
}

.gallery-item {
  width: 339px;  /* 308px * 1.1 = 339px (10% bigger) */
  height: 440px; /* 400px * 1.1 = 440px (10% bigger) */
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
  border-radius: 10px;
}

/* Only apply hover effects on devices that support hover */
@media (hover: hover) {
  /* Individual image hover effects */
  .gallery-item:hover {
    transform: scale(1.2); /* 20% bigger on hover */
    z-index: 10;
  }
  
  /* Ensure hover effects work properly at different screen sizes */
  @media screen and (max-width: 768px) {
    .gallery-item:hover {
      transform: scale(1.2);
    }
  }
  
  @media screen and (max-width: 480px) {
    .gallery-item:hover {
      transform: scale(1.2);
    }
  }
}

.gallery-header {
  text-align: center;
  color: #fff;
  font-size: 70px; /* Adjust the font size as needed */
  margin-top: 200px; /* Adjust the margin as needed */
  font-weight: 600;
}

.gallery-description {
  text-align: center;
  color: #E5B761;
  font-size: 30px; /* Adjust the font size as needed */
  margin: 10px auto; /* Center the element horizontally */
  max-width: 800px; /* Adjust the max-width to make it more condensed */
}

/* new one */

.gallery-header1 {
  text-align: center;
  color: #fff;
  font-size: 70px; /* Adjust the font size as needed */
  margin-top: 100px; /* Adjust the margin as needed */
  font-weight: 600;
}

.gallery-description1 {
  text-align: center;
  color: #E5B761;
  font-size: 30px; /* Adjust the font size as needed */
  margin: 10px auto; /* Center the element horizontally */
  max-width: 700px; /* Adjust the max-width to make it more condensed */
}

.section-container {
  width: 100%;
  margin-bottom: 50px;
  box-sizing: border-box;
}

.list-with-image1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px; /* Adjust the margin as needed */
  flex-direction: row-reverse; /* Place the image on the right side for large screens */
  flex-wrap: wrap;
  padding: 0 20px;
  box-sizing: border-box;
}

.list-image1 {
  margin-top: 40px;
  margin-left: 40px; /* Adjust the margin as needed */
  max-width: 450px; /* Adjust the width as needed */
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; 
  margin-bottom: 50px; /* Reduced from 200px for better spacing */
  object-fit: contain;
  order: 1; /* Default order for large screens */
}

.gallery-list1 {
  text-align: left; 
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 20px 0; /* Adjust the margin as needed */
  max-width: 500px; /* Adjust the max-width to make it more condensed */
  width: 100%;
  margin-bottom: 50px; /* Reduced from 200px for better spacing */
  box-sizing: border-box;
  order: 2; /* Default order for large screens */
}

.gallery-list1 li {
  font-size: 25px; /* Adjust the font size as needed */
  color: #fff; /* White text color */
  margin-bottom: 20px; /* Adjust the margin as needed */
  line-height: 1.5; /* Adjust the line height as needed */
}

/* new one */

.list-with-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px; /* Adjust the margin as needed */
  flex-wrap: wrap;
  padding: 0 20px;
  box-sizing: border-box;
  flex-direction: row; /* Default direction for large screens */
}

.list-image {
  margin-top: 40px;
  margin-right: 40px; /* Adjust the margin as needed */
  max-width: 450px; /* Adjust the width as needed */
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; 
  object-fit: contain;
  order: 1; /* Default order for large screens */
}

.gallery-list {
  text-align: left; 
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 20px 0; /* Adjust the margin as needed */
  max-width: 500px; /* Adjust the max-width to make it more condensed */
  width: 100%;
  box-sizing: border-box;
  order: 2; /* Default order for large screens */
}

.gallery-list li {
  font-size: 25px; /* Adjust the font size as needed */
  color: #fff; /* White text color */
  margin-bottom: 20px; /* Adjust the margin as needed */
  line-height: 1.5; /* Adjust the line height as needed */
}

@keyframes slideRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
/* Media Queries for Responsive Design */
@media screen and (max-width: 1200px) {
  .gallery-header, .gallery-header1 {
    font-size: 60px;
  }
  
  .gallery-description, .gallery-description1 {
    font-size: 26px;
  }
  
  .gallery-list li, .gallery-list1 li {
    font-size: 22px;
  }
}

@media screen and (max-width: 992px) {
  .gallery-wrap {
    margin-top: 300px;
  }
  
  .gallery-header, .gallery-header1 {
    font-size: 50px;
    margin-top: 60px;
  }
  
  .gallery-description, .gallery-description1 {
    font-size: 22px;
  }
  
  .list-with-image, .list-with-image1 {
    padding: 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .gallery-wrap {
    margin-top: 200px;
  }
  
  .section-container {
    margin-bottom: 80px;
  }
  
  .gallery-header, .gallery-header1 {
    font-size: 36px;
    margin-top: 40px;
  }

  .gallery-description, .gallery-description1 {
    font-size: 18px;
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .list-with-image, .list-with-image1 {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .list-image, .list-image1 {
    width: 90%;
    max-width: 350px;
    margin: 20px auto;
    order: 2; /* Image below text on small screens */
    margin-bottom: 40px;
  }

  .gallery-list, .gallery-list1 {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    order: 1; /* Text above image on small screens */
    margin-bottom: 20px;
  }

  .gallery-list li, .gallery-list1 li {
    font-size: 16px;
    padding-left: 20px;
    text-indent: -20px;
    margin-bottom: 15px;
    line-height: 1.4;
    white-space: normal;
  }

  .gallery-item {
    width: 275px;  /* 250px * 1.1 = 275px (10% bigger) */
    height: 352px; /* 320px * 1.1 = 352px (10% bigger) */
  }

  .gallery-group {
    gap: 20px;
  }
  
  .gallery-wrap h2 {
    padding: 20px;
    font-size: 30px;
  }
}

@media screen and (max-width: 480px) {
  .gallery-wrap {
    margin-top: 150px;
  }
  
  .section-container {
    margin-bottom: 60px;
  }
  
  .gallery-header, .gallery-header1 {
    font-size: 28px;
    margin-top: 30px;
  }

  .gallery-description, .gallery-description1 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .list-image, .list-image1 {
    margin-bottom: 30px;
  }

  .gallery-list li, .gallery-list1 li {
    font-size: 14px;
    padding-left: 15px;
    text-indent: -15px;
  }

  .gallery-item {
    width: 220px;  /* 200px * 1.1 = 220px (10% bigger) */
    height: 286px; /* 260px * 1.1 = 286px (10% bigger) */
  }
  
  .gallery-wrap h2 {
    padding: 15px;
    font-size: 24px;
  }
}

.mag1 { 
  background-image: url('../../assets/1.jpg'); 
  background-size: cover;
  background-position: center;
  width: 339px;  /* 308px * 1.1 = 339px (10% bigger) */
  height: 440px; /* 400px * 1.1 = 440px (10% bigger) */
  border-radius: 10px;
}
.mag2 { 
  background-image: url('../../assets/2.jpg'); 
  background-size: cover;
  background-position: center;
  width: 339px;  /* 308px * 1.1 = 339px (10% bigger) */
  height: 440px; /* 400px * 1.1 = 440px (10% bigger) */
  border-radius: 10px;
}
.mag3 { 
  background-image: url('../../assets/3.jpg'); 
  background-size: cover;
  background-position: center;
  width: 339px;  /* 308px * 1.1 = 339px (10% bigger) */
  height: 440px; /* 400px * 1.1 = 440px (10% bigger) */
  border-radius: 10px;
}
.mag4 { 
  background-image: url('../../assets/4.jpg'); 
  background-size: cover;
  background-position: center;
  width: 339px;  /* 308px * 1.1 = 339px (10% bigger) */
  height: 440px; /* 400px * 1.1 = 440px (10% bigger) */
  border-radius: 10px;
}
.mag5 { 
  background-image: url('../../assets/5.jpg'); 
  background-size: cover;
  background-position: center;
  width: 339px;  /* 308px * 1.1 = 339px (10% bigger) */
  height: 440px; /* 400px * 1.1 = 440px (10% bigger) */
  border-radius: 10px;
}
.mag6 { 
  background-image: url('../../assets/6.jpg'); 
  background-size: cover;
  background-position: center;
  width: 339px;  /* 308px * 1.1 = 339px (10% bigger) */
  height: 440px; /* 400px * 1.1 = 440px (10% bigger) */
  border-radius: 10px;
}
.mag7 { 
  background-image: url('../../assets/3.jpg'); 
  background-size: cover;
  background-position: center;
  width: 339px;  /* 308px * 1.1 = 339px (10% bigger) */
  height: 440px; /* 400px * 1.1 = 440px (10% bigger) */
  border-radius: 10px;
}
.mag8 { 
  background-image: url('../../assets/4.jpg'); 
  background-size: cover;
  background-position: center;
  width: 339px;  /* 308px * 1.1 = 339px (10% bigger) */
  height: 440px; /* 400px * 1.1 = 440px (10% bigger) */
  border-radius: 10px;
}